import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  min-height: 100vh;

  position: relative;

  padding-top: 6.5rem;

  @media (max-width: 520px) {
    padding-top: 9.5rem;
  }
`;


export const WarningContainer = styled.div`
  width: 100%;
  height: 6.5rem;

  position: absolute;
  top: 0;
  z-index: 999;

  display: flex;
  gap: 0.125rem;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;

  background-color: #ffbdbd;
  border-bottom: 2px solid red;

  padding: 0 4rem;

  @media (max-width: 520px) {
    gap: 0.125rem;
    padding: 0 1rem;
    height: 9.5rem;
  }
`;

export const WarningTitle = styled.strong`
  font-size: 1.25rem;
  font-weight: 800;
  color: #000;
`;

export const WarningDescription = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: #333;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  max-width: calc(100vw - 18.5rem);
  width: 100%;

  @media screen and (max-width: 520px) {
    max-width: 100%;
  }
`;

export const Content = styled.div`
  background-color: #f5f5f6;

  padding: 0 2rem;

  height: 100%;

  @media (max-width: 520px) {
    padding: 0 5%;
  }
`;
