import React from 'react';
import {
  Background,
  Container,
  HiddenLogo,
  LeftContainer,
  RightContainer,
  WarningContainer,
  WarningDescription,
  WarningTitle,
} from './auth-styles';

import { Redirect } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { userURLByRole } from '../../helper/UserURLByRole';
import { UserRoles } from '../../types/users';
import { differenceInDays } from 'date-fns';

const AuthLayout: React.FC = ({ children }) => {
  const { user } = useAuth();

  const targetDate = new Date(2025, 1, 24);
  const now = new Date();
  const daysRemaining = Math.max(differenceInDays(targetDate, now) + 1, 0);

  if (user) {
    return (
      <Redirect
        to={userURLByRole(user.role as keyof typeof UserRoles).loggedIn}
      />
    );
  }

  return (
    <Background>
      <Container>
        <div>
          <LeftContainer>
            <HiddenLogo>
              <WarningContainer>
                <WarningTitle>AVISO!</WarningTitle>
                <WarningDescription>
                  Informamos que foram identificadas irregularidades contratuais e,
                  conforme diretrizes, será necessária a regularização no prazo máximo de {daysRemaining} dias,
                  sob pena de suspensão do sistema. Para mais informações, entre em contato com o Franqueador.
                </WarningDescription>
              </WarningContainer>
            </HiddenLogo>
            {children}
          </LeftContainer>
          <RightContainer>
            <WarningContainer>
              <WarningTitle>AVISO!</WarningTitle>
              <WarningDescription>
                Informamos que foram identificadas irregularidades contratuais e,
                conforme diretrizes, será necessária a regularização no prazo máximo de {daysRemaining} dias,
                sob pena de suspensão do sistema. Para mais informações, entre em contato com o Franqueador.
              </WarningDescription>
            </WarningContainer>
          </RightContainer>
        </div>
        <strong>NOSSA UNIÃO É TRANSFORMADORA</strong>
      </Container>
    </Background>
  );
};

export default AuthLayout;
