import { differenceInDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Header, Menu } from '../../components';
import { InfoProfitabilityModal } from '../../components/modal/info-profitability';
import { useAuth } from '../../contexts/auth';
import productsApi from '../../services/products';
import { Container, Content, Wrapper, WarningContainer, WarningTitle, WarningDescription } from './styles';

export type Profitability = {
  minimun: number;
  ideal: number;
  salesCommission: number;
  referralCommission: number;
  invoiceValue: number;
  royalties: number;
  franchiseId: string;
};

const DefaultLayout: React.FC = ({ children }) => {
  const history = useHistory();

  const [profitabilityModal, setProfitabilityModal] = useState({
    isOpen: false,
    isAdmin: false,
  });

  const { franchises, selectedFranchise } = useAuth();

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebar = () => {
    setOpenSidebar((state) => !state);
  };

  const handleOutSideClick = () => {
    setOpenSidebar(false);
  };

  const getProfitability = async (
    franchiseId: string
  ): Promise<Profitability> => {
    try {
      const { data } = await productsApi.get<Profitability>(
        '/budgets/profitability',
        {
          params: {
            franchiseId,
          },
        }
      );

      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleProfitabilityConfigMessage = async () => {
    try {
      if (!selectedFranchise) {
        return;
      }

      const profitability = await getProfitability(selectedFranchise);

      const notConfiguredFranchise =
        !profitability.invoiceValue || profitability.invoiceValue === 0;

      if (notConfiguredFranchise) {
        setProfitabilityModal({
          isOpen: true,
          isAdmin: franchises.some(
            (franchise) =>
              franchise.id === selectedFranchise &&
              franchise.role.includes('Administrador')
          ),
        });
      }
    } catch (error) {
      console.error('Error handling profitability config message: ', error);
    }
  };

  useEffect(() => {
    handleProfitabilityConfigMessage();
  }, [selectedFranchise]);

  const targetDate = new Date(2025, 1, 24);
  const now = new Date();
  const daysRemaining = Math.max(differenceInDays(targetDate, now) + 1, 0);

  return (
    <Container>
      <WarningContainer>
        <WarningTitle>Aviso</WarningTitle>
        <WarningDescription>
          Informamos que foram identificadas irregularidades contratuais e,
          conforme diretrizes, será necessária a regularização no prazo máximo de {daysRemaining} dias,
          sob pena de suspensão do sistema. Para mais informações, entre em contato com o Franqueador.
        </WarningDescription>
      </WarningContainer>

      <Menu
        open={openSidebar}
        handleSidebar={handleSidebar}
        handleOutSideClick={handleOutSideClick}
      />
      <Wrapper>
        <Header onOpenSidebar={handleSidebar} />
        <Content>{children}</Content>
      </Wrapper>

      <InfoProfitabilityModal
        isOpen={profitabilityModal.isOpen}
        isAdmin={profitabilityModal.isAdmin}
        onConfirm={() => {
          history.push('/orcamentos/configurar-lucratividade');

          setProfitabilityModal((state) => ({ ...state, isOpen: false }));
        }}
        onCancel={() =>
          setProfitabilityModal((state) => ({ ...state, isOpen: false }))
        }
      />
    </Container>
  );
};

export default DefaultLayout;
